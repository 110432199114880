//事例スライダー
.c-workSlider {
  &__list {}
  &__item {
    width: 100%;
    padding-top: (380 / 600 * 100%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
  }
  &__label {
    background-color: rgba($keyColor, .8);
    margin: 0;
    width: 100%;
    color: $white;
    padding: 7px 5px;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  //パーツ調整
  .c-box {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .slick-dots {
    display: flex;
    justify-content: center;
    li {
      max-width: 69px;
      // width: 100%;
      height: 13px;
      margin: 0 15px !important;
      button {
        width: 100%;
        height: 100%;
        padding: 0;
        &:before {
          content: '';
          width: 100%;
          height: 100%;
          opacity: 1;
          background-color: #C6C6C6;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          @media screen and (max-width: 568px) {
            width: 15px;
            height: 15px;
          }
        }
      }

      &.slick-active {
        button {
          &:before {
            opacity: 1;
            background-color: $keyColor;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            @media screen and (max-width: 568px) {
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }
  }
}

.p-restaurant-slider {
  padding-top: (250 / 600 * 100%) !important;
}
.p-workSlider-pt60 {
  padding-top: 60px;
  @media screen and (max-width: 768px) {
    padding-top: 0px;
  }
}